import { atom, getDefaultStore, useAtomValue } from 'jotai';
import moment from 'moment';

import { UpdateProxyStatusesParams } from './types/update-proxy-statuses.types';
import { getProxyStatusParams } from '../../../features/proxy/proxy-helpers';
import { IProxy } from '../../../interfaces';
import { setProxyStatuses as setProxyStatusesRequest, setSharedProxyStatuses } from '../../proxies.context/api';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { IUpdateProxyItem } from '../../proxies.context/interfaces/update-proxy-item.interface';
import { updateProxyItem } from '../proxy-list.atom';

const proxyStatusesAtom = atom<IProxy[]>([]);
const proxyStatusesProfilesIdsAtom = atom<string[]>([]);
const currentlyRestoringProxyIdsAtom = atom<string[]>([]);

const getProxyStatuses = (): IProxy[] => getDefaultStore().get(proxyStatusesAtom);
const setProxyStatuses = (proxies: IProxy[]): void => getDefaultStore().set(proxyStatusesAtom, proxies);
const getProxyStatusesProfilesIds = (): string[] => getDefaultStore().get(proxyStatusesProfilesIdsAtom);
const setProxyStatusesProfilesIds = (profilesIds: string[]): void => getDefaultStore().set(proxyStatusesProfilesIdsAtom, profilesIds);
const getCurrentlyRestoringProxyIds = (): string[] => getDefaultStore().get(currentlyRestoringProxyIdsAtom);
const setCurrentlyRestoringProxyIds = (proxyIds: string[]): void => getDefaultStore().set(currentlyRestoringProxyIdsAtom, proxyIds);

const useProxyStatuses = (): IProxy[] => useAtomValue(proxyStatusesAtom);
const useProxyStatusesProfilesIds = (): string[] => useAtomValue(proxyStatusesProfilesIdsAtom);
const useCurrentlyRestoringProxyIds = (): string[] => useAtomValue(currentlyRestoringProxyIdsAtom);

export const useIsProxyChecking = (proxy: IProxy, profileId = ''): boolean => {
  const proxiesStatuses = useProxyStatuses();
  const proxyStatusesProfilesIds = useProxyStatusesProfilesIds();

  if (!proxy) {
    return false;
  }

  const isProxyChecking = proxiesStatuses.some(proxyChecking => proxy.id === proxyChecking.id);
  const isProfileCurrentOrNoProfile = profileId && proxyStatusesProfilesIds.length ? proxyStatusesProfilesIds.includes(profileId) : true;

  return isProxyChecking && isProfileCurrentOrNoProfile;
};

export const useIsProxyRestoring = (proxyId: string): boolean => {
  const proxyIdRestoreStatuses = useCurrentlyRestoringProxyIds();
  if (!proxyId) {
    return false;
  }

  return proxyIdRestoreStatuses.includes(proxyId);
};

export const addProxyStatuses = (proxies: IProxy[], profileIds: string[] = []): void => {
  const proxyStatuses = getProxyStatuses();
  const proxyStatusesProfileIds = getProxyStatusesProfilesIds();

  const newProxyStatuses = [...proxyStatuses, ...proxies];

  setProxyStatuses(newProxyStatuses);
  const profileIdsTruthy = profileIds.filter(Boolean);
  if (profileIdsTruthy.length) {
    const newProxyStatusesProfileIds = [...proxyStatusesProfileIds, ...profileIdsTruthy];
    setProxyStatusesProfilesIds(newProxyStatusesProfileIds);
  }
};

export const removeProxyStatuses = (proxies: IProxy[], profileIds: string[] = []): void => {
  const proxyStatuses = getProxyStatuses();
  const proxyStatusesProfileIds = getProxyStatusesProfilesIds();

  const newProxyStatuses = proxyStatuses
    .filter(proxyStatus => !proxies.some(proxy => proxyStatus.id === proxy.id));

  const newProxyStatusesProfileIds = proxyStatusesProfileIds
    .filter(proxyStatusProfileId => !profileIds.includes(proxyStatusProfileId));

  setProxyStatuses(newProxyStatuses);
  setProxyStatusesProfilesIds(newProxyStatusesProfileIds);
};

export const resetProxyStatuses = (): void => {
  setProxyStatuses([]);
  setProxyStatusesProfilesIds([]);
};

export const updateProxyStatuses = async (params: UpdateProxyStatusesParams): Promise<ISetProxyStatusParams|void> => {
  const { proxies, profileId = '', isSharedProxy = false, shouldRestoreProxy = true } = params;
  addProxyStatuses(proxies, [profileId]);

  const [proxyToCheck] = proxies;

  const { mode, host, port, username, password } = proxyToCheck;

  // TODO: reuse cloned parts (also, used before)
  const statusParams = await getProxyStatusParams({
    ...proxyToCheck,
    mode,
    host,
    port,
    username,
    password,
  }).catch(() => null);

  if (!statusParams) {
    return removeProxyStatuses(proxies, [profileId].filter(Boolean));
  }

  const proxyChecked: IUpdateProxyItem = {
    ...proxyToCheck,
    ...statusParams,
    checkDate: moment().toDate(),
    isInvisible: isSharedProxy,
  };

  updateProxyItem(proxyChecked);

  const checkParams: ISetProxyStatusParams = {
    id: '',
    status: statusParams.status,
    country: statusParams.country || proxyToCheck?.country,
    city: statusParams.city || proxyToCheck?.city,
    error: statusParams.error,
    checkDate: moment().unix(),
    lastIp: statusParams.origin,
    timezone: statusParams.timezone,
    languages: statusParams.languages,
    shouldRestoreProxy,
  };

  if (proxyToCheck.id) {
    checkParams.id = proxyToCheck.id;
    if (isSharedProxy) {
      setSharedProxyStatuses([{ ...checkParams, profileId }]).catch(() => null);
    } else {
      setProxyStatusesRequest([checkParams]).catch(() => null);
    }
  }

  removeProxyStatuses(proxies, [profileId].filter(Boolean));

  return checkParams;
};

export const onProxyRestoreStarted = (proxyIds: string[]): void => {
  const proxyIdsRestoreStatuses = getCurrentlyRestoringProxyIds();
  const newProxyIdsRestoreStatuses = [...proxyIdsRestoreStatuses, ...proxyIds];
  setCurrentlyRestoringProxyIds(newProxyIdsRestoreStatuses);
};

export const onProxyRestoreDone = (proxyIds: string[]): void => {
  const proxyIdRestoreStatuses = getCurrentlyRestoringProxyIds();

  const newProxyIdStatuses = proxyIdRestoreStatuses
    .filter(proxyIdStatus => !proxyIds.some(proxy => proxyIdStatus === proxy));

  setCurrentlyRestoringProxyIds(newProxyIdStatuses);
};

export const resetProxyRestoreStatuses = (): void => setCurrentlyRestoringProxyIds([]);
